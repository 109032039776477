<template>
  <div class="conseillerAccordDocumentsProfile">

    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
      <div class="text-center">
        <p>Une erreur s'est produite lors du chargement des informations de l'accord,
          veuillez réactualiser la page ou nous contacter directement.
        </p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <!-- Document Uploaded -->
    <b-modal ref="accordDocumentUploadedModal" size="lg" id="accordDocumentUploadedModal" hide-footer title="Votre document a bien été envoyé et enregistré">
      <div class="text-center">
        <p>
          <strong>Votre document a bien été envoyé.</strong> <br>
        </p>
        <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('accordDocumentUploadedModal')">Fermer</b-button>
      </div>
    </b-modal>

    <!-- Create Document -->
    <b-modal ref="createAccordDocumentModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Charger votre document </strong>
      </template>
      <div>
        <b-form>
          <b-form-group>
              <b-form-file
                class="text-truncate"
                browse-text='Parcourir'
                required
                v-model="createAccordDocumentFile"
                id="create-document-file-input"
                placeholder="Choisissez un fichier"
                drop-placeholder="Déposez un fichier ici"
                @input="$v.createAccordDocumentFile.$touch()"
                :state="$v.createAccordDocumentFile.$dirty ? !$v.createAccordDocumentFile.$error : null"
              ></b-form-file>
              <div class="form-error" v-if="!$v.createAccordDocumentFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 2 MB.</div>
              <div class="form-error" v-if="!$v.createAccordDocumentFile.file_extension_validation">Attention, votre fichier doit être au format pdf, jpg, jpeg ou png</div>
              <div class="form-error" v-if="!$v.createAccordDocumentFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
              <div class="form-error" v-if="!$v.createAccordDocumentFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
          </b-form-group>
          <b-button
            pill block variant="outline-dark" class="mt-2"
            @click='createAccordDocument'
            :disabled="createAccordDocumentInProcess || $v.createAccordDocumentFile.$invalid  ">
              Charger votre document
          </b-button>
        </b-form>
      </div>
    </b-modal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#BB3634">
    </loading>


    <div 
      id="revenu-brut-global-section" 
      :class="pdfViewerType === 'IMPOTS' || pdfViewerType === 'AVSITUA' ? 'fixed-top container' : ''" 
      style="background-color: white;"
    >
      <h2>Revenu brut global - {{situationRender(accord.beneficiaire.situation)}}</h2>
      
      <div class="row">
        <div class="col-12 col-md-5">
          <label
            v-if="enCouple" 
            for="accord-rfrValue-input"
          >
            Revenu brut global du couple <br>
            <small>(ou revenu brut global du bénéficiaire  en cas de déclaration séparée)</small>
          </label>
          <label
            v-else 
            for="accord-rfrValue-input"
          >
            Revenu brut global du bénéficiaire
          </label>
          <b-form-input
            type="number"
            step="1"
            v-model.trim.lazy="accord.rfr_value"
            id="accord-rfrValue-input"
            @input="$v.accord.rfr_value.$touch()"
            :state="$v.accord.rfr_value.$dirty ? !$v.accord.rfr_value.$error : null"
            placeholder="Revenu brut global en euros">
          </b-form-input>
        </div>
        <div
          v-if="enCouple" 
          class="col-12 col-md-4"
        >
          <label
            for="accord-rfrConjointValue-input"
          >
            Revenu brut global du conjoint <br>
            <small>(en cas de déclaration séparée)</small>
          </label>
          <b-form-input
            type="number"
            step="1"
            v-model.trim.lazy="accord.rfr_conjoint_value"
            id="accord--input"
            @input="$v.accord.rfr_conjoint_value.$touch()"
            :state="$v.accord.rfr_conjoint_value.$dirty ? !$v.accord.rfr_conjoint_value.$error : null"
            placeholder="Revenu brut global du conjoint en euros">
          </b-form-input>
        </div>
        <div :class="enCouple ? 'col-12 col-md-3' : 'col-12-col-md-5'">
          <label
            for="accord-rfrNbEnfantsCharge-input"
          >
            Nombre d'enfants fiscalement à charge
          </label>
          <b-form-input
            type="number"
            step="1"
            v-model.trim.lazy="accord.rfr_nb_children_fiscalement_a_charge"
            id="accord-rfrNbEnfantsCharge-input"
            @input="$v.accord.rfr_nb_children_fiscalement_a_charge.$touch()"
            :state="$v.accord.rfr_nb_children_fiscalement_a_charge.$dirty ? !$v.accord.rfr_nb_children_fiscalement_a_charge.$error : null"
            placeholder="Nombre d'enfants">
          </b-form-input>
        </div>
      </div>
      <div class="row mt-2">
        <div
          v-if="!enCouple"
            class="col-12 col-md-4 mt-2"
        >
          <p>
            Abattements fiscaux (du demandeur)
          </p>
          <div
            class="abattement-item"
            v-for="(abattement, index) in abattements" :key="`abattements-${index}`"
          >
            <div>{{abattement.montant}} €</div>
            <div
              @click="deleteAbattement(abattement.id)"
              class="abattement-item--close-window"
              v-b-popover.hover.top="'Supprimer'"
            >
              <div class="abattement-item--close-window--cross">&#x2716;</div>
            </div>
          </div>

          <b-input-group
            v-if="abattements.length < 6"
            class="mt-3"
          >
            <b-form-input
              type="number"
              step="1"
              @keyup.enter="addAbattement('DEMANDEUR')"
              v-model="abattementValue"
            />
            <b-input-group-append>
              <b-button
                @click="addAbattement('DEMANDEUR')"
                variant="outline-success"
              >
                Ajouter
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div
          v-if="enCouple"
          class="col-12 col-md-4 mt-2"
        >
          <p>
            Abattements fiscaux du couple <br>
            <small>(ou du demandeur en cas de déclaration séparée)</small>
          </p>
          <div
            class="abattement-item"
            v-for="(abattement, index) in abattementsCouple" :key="`abattements-couple-${index}`"
          >
            <div>{{abattement.montant}} €</div>
            <div
              @click="deleteAbattement(abattement.id)"
              class="abattement-item--close-window"
              v-b-popover.hover.top="'Supprimer'"
            >
              <div class="abattement-item--close-window--cross">&#x2716;</div>
            </div>
          </div>
          <b-input-group
            v-if="abattementsCouple.length < 6"
            class="mt-3"
          >
            <b-form-input
              type="number"
              step="1"
              v-model="abattementCoupleValue"
              @keyup.enter="addAbattement('COUPLE')"
            />
            <b-input-group-append>
              <b-button @click="addAbattement('COUPLE')" variant="outline-success">Ajouter</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div
          v-if="enCouple"
          class="col-12 col-md-4 mt-2"
        >
          <p>
            Abattements fiscaux du conjoint <br>
            <small>(en cas de déclaration séparée)</small>
          </p>
          <div
            class="abattement-item"
            v-for="(abattement, index) in abattementsConjoint" :key="`abattements-conjoint-${index}`"
          >
            <div>{{abattement.montant}} €</div>
            <div
              @click="deleteAbattement(abattement.id)"
              class="abattement-item--close-window"
              v-b-popover.hover.top="'Supprimer'"
            >
              <div class="abattement-item--close-window--cross">&#x2716;</div>
            </div>
          </div>
          <b-input-group
            v-if="abattementsConjoint.length < 6"
            class="mt-3"
          >
            <b-form-input
              type="number"
              step="1"
              v-model="abattementConjointValue"
              @keyup.enter="addAbattement('CONJOINT')"
            />
            <b-input-group-append>
              <b-button @click="addAbattement('CONJOINT')" variant="outline-success">Ajouter</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-12 text-center">
          <b-button
            block variant="outline-success" size="sm" pill
            @click="modifyAccordRfrInformation"
            :disabled="modifyAccordInProcess || $v.accord.rfr_value.$invalid || $v.accord.rfr_conjoint_value.$invalid || $v.accord.rfr_nb_children_fiscalement_a_charge.$invalid
              || accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
              Enregistrer ces informations
          </b-button>
          <small v-if="accord.state === '6MREFUSED'" class="text-danger">Demande d'accord refusée</small>
          <small v-if="accord.state === '7MVALIDATED'" class="text-success">Demande d'accord déjà acceptée</small>
        </div>
      </div>

      <p class="mt-2" v-if="rfrTotal > rfrPlafond">
        <strong class="text-danger">
          L'ensemble des revenus bruts du foyer avant tout abattement
          ({{formatThousandSeparatorNumber(rfrTotal)}} €) sont supérieurs
          au plafond autorisé
          ({{formatThousandSeparatorNumber(rfrPlafond)}} €) pour pouvoir
          réaliser une demande d'aide
        </strong>
      </p>

      <p class="mt-2" v-else>
        <strong class="text-success">
          L'ensemble des revenus bruts du foyer avant tout abattement
          ({{formatThousandSeparatorNumber(rfrTotal)}} €) sont inférieurs
          au plafond autorisé
          ({{formatThousandSeparatorNumber(rfrPlafond)}} €) pour pouvoir
          réaliser une demande d'aide
        </strong>
      </p>
    </div>
    


    <h2>Documents liés à la demande d'accord</h2>

    <!-- //////////////////////// ATTESTATION //////////////// -->
    <div class="row mt-3">
      <div class="col-12">
        <h3 style=" margin-bottom: 0px;"> Attestation sur l'honneur </h3>
        <em class="text-secondary">
          Il s'agit de l'attestation sur l'honneur remplie et signée ou des cases cochées
        </em><br>

      </div>
    </div>

    <div>
      Le bénéficiaire : <br>
      <ul>
        <li>
          certifie sur l'honneur ne percevoir aucune autre aide liée à la garde d'enfants
          (aide du département, de la région, d'une entreprise par exemple) pour les dépenses
          dont il demande le remboursement : <strong> {{trueFalseRender(this.accord.is_no_other_aides)}}</strong>
        </li>
        <li>
          certifie l’exactitude des informations founies : <strong> {{trueFalseRender(this.accord.is_information_certified)}}</strong>
        </li>
      </ul>
    </div>

    <div v-if="attestDocs.length === 0">
      <div class="row">
        <div class="col-sm-12 mt-2 mb-3 text-center">
          <em>Aucun document encore chargé </em>
        </div>
      </div>
    </div>

    <div v-else>
      <b-table
        responsive class="align-middle text-center mt-4 cursor-table"
        headVariant="light"
        :fields="documentsRenderFields"
        :items="attestDocs"
        >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template v-slot:cell(file_name)="data">
          Attestation<br>
          <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
        </template>

        <template v-slot:cell(state)="data">
          <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
          <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
          <strong v-else class="text-secondary">A valider</strong>
        </template>

        <template v-slot:cell(actions)="data">
          <div v-if="accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <em v-if="accord.state === '6MREFUSED'" class="text-secondary">Refusée par le manager</em>
            <em v-if="accord.state === '7MVALIDATED'" class="text-secondary"> Validée par le manager</em>
          </div>
          <div v-else>
            <b-button
              v-b-popover.hover.top="'Visualiser en pdf'"
              variant="outline-danger" class="ml-2" size="sm"
              @click="conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, true, 'ATTEST')">
              <v-icon name="file-pdf"/>
            </b-button>
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'VALIDATED'"
              variant="outline-success" class="ml-2" size="sm"
              :disabled="validateDocumentInProcess"
              @click='conseillerValidateAccordDocument(data.item.id)'>
              <v-icon name="check-circle"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'REFUSED'"
              variant="outline-danger" class="ml-2" size="sm"
              :disabled="refuseDocumentInProcess"
              @click='conseillerRefuseAccordDocument(data.item.id)'>
              <v-icon name="times-circle"/>
            </b-button>
          </div>
        </template>
      </b-table>

      <pdfViewer
        v-if="pdfViewerType === 'ATTEST'"
        :pdfSrc="pdfSrc"
        @close="closePdfViewer"
      />

    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <b-button
          block variant="outline-info" size="sm" pill
          @click="showCreateAccordDocumentModal('ATTEST')">
            Ajouter un fichier
        </b-button>
      </div>
    </div>

    <hr>

    <!-- //////////////////////// ACTE DE NAISSANCE //////////////// -->
    <div class="row mt-3">
      <div class="col-12">
        <h3 style=" margin-bottom: 0px;"> Acte(s) de naissance ou d'adoption </h3>
        <em class="text-secondary">
          Il s'agit de(s) acte(s) de naissance ou d'adoption de ou des enfant(s) faisant l'objet de la garde.
        </em><br>

      </div>
    </div>

    <div v-if="naissanceDocs.length === 0">
      <div class="row">
        <div class="col-sm-12 mt-2 mb-3 text-center">
          <em>Aucun document encore chargé </em>
        </div>
      </div>
    </div>

    <div v-else>
      <b-table
        responsive class="align-middle text-center mt-4 cursor-table"
        headVariant="light"
        :fields="documentsRenderFields"
        :items="naissanceDocs"
        >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template v-slot:cell(file_name)="data">
          Acte de naissance<br>
          <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
        </template>

        <template v-slot:cell(state)="data">
          <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
          <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
          <strong v-else class="text-secondary">A valider</strong>
        </template>

        <template v-slot:cell(actions)="data">
          <div v-if="accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <em v-if="accord.state === '6MREFUSED'" class="text-secondary">Refusée par le manager</em>
            <em v-if="accord.state === '7MVALIDATED'" class="text-secondary"> Validée par le manager</em>
          </div>
          <div v-else>
            <b-button
              v-b-popover.hover.top="'Visualiser en pdf'"
              variant="outline-danger" class="ml-2" size="sm"
              @click="conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, true, 'NAISSANCE')">
              <v-icon name="file-pdf"/>
            </b-button>
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'VALIDATED'"
              variant="outline-success" class="ml-2" size="sm"
              :disabled="validateDocumentInProcess"
              @click='conseillerValidateAccordDocument(data.item.id)'>
              <v-icon name="check-circle"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'REFUSED'"
              variant="outline-danger" class="ml-2" size="sm"
              :disabled="refuseDocumentInProcess"
              @click='conseillerRefuseAccordDocument(data.item.id)'>
              <v-icon name="times-circle"/>
            </b-button>
          </div>
        </template>
      </b-table>

      <pdfViewer
        v-if="pdfViewerType === 'NAISSANCE'"
        :pdfSrc="pdfSrc"
        @close="closePdfViewer"
      />
    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <b-button
          block variant="outline-info" size="sm" pill
          @click="showCreateAccordDocumentModal('NAISSANCE')">
            Ajouter un fichier
        </b-button>
      </div>
    </div>

    <hr>

    <!-- //////////////////////// AVIS D'IMPOSITION //////////////// -->
    <div class="row mt-3">
      <div class="col-12">
        <h3 style=" margin-bottom: 0px;"> Avis d'imposition </h3>
        <em class="text-secondary">
          Il s'agit de la copie intégrale de l'avis d'imposition de l'année {{accord.year - 1}} sur les revenus {{accord.year - 2}}
          du bénéficiaire ainsi que celui du conjoint en cas de vie maritale.<br>
        </em>
      </div>
    </div>

    <div v-if="impotDocs.length === 0">
      <div class="row">
        <div class="col-sm-12 mt-2 mb-3 text-center">
          <em>Aucun document encore chargé </em>
        </div>
      </div>
    </div>

    <div v-else>
      <b-table
        responsive class="align-middle text-center mt-4 cursor-table"
        headVariant="light"
        :fields="documentsRenderFields"
        :items="impotDocs"
        >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template v-slot:cell(file_name)="data">
          Avis d'imposition<br>
          <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
        </template>

        <template v-slot:cell(state)="data">
          <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
          <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
          <strong v-else class="text-secondary">A valider</strong>
        </template>

        <template v-slot:cell(actions)="data">
          <div v-if="accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <em v-if="accord.state === '6MREFUSED'" class="text-secondary">Refusée par le manager</em>
            <em v-if="accord.state === '7MVALIDATED'" class="text-secondary"> Validée par le manager</em>
          </div>
          <div v-else>
            <b-button
              v-b-popover.hover.top="'Visualiser en pdf'"
              variant="outline-danger" class="ml-2" size="sm"
              @click="conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, true, 'IMPOTS')">
              <v-icon name="file-pdf"/>
            </b-button>
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'VALIDATED'"
              variant="outline-success" class="ml-2" size="sm"
              :disabled="validateDocumentInProcess"
              @click='conseillerValidateAccordDocument(data.item.id)'>
              <v-icon name="check-circle"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'REFUSED'"
              variant="outline-danger" class="ml-2" size="sm"
              :disabled="refuseDocumentInProcess"
              @click='conseillerRefuseAccordDocument(data.item.id)'>
              <v-icon name="times-circle"/>
            </b-button>
          </div>
        </template>
      </b-table>

      <pdfViewer
        v-if="pdfViewerType === 'IMPOTS'"
        :pdfSrc="pdfSrc"
        @close="closePdfViewer"
      />

    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <b-button
          block variant="outline-info" size="sm" pill
          @click="showCreateAccordDocumentModal('IMPOTS')">
            Ajouter un fichier
        </b-button>
      </div>
    </div>

    <hr>

    <!-- //////////////////////// AVIS DE DE SITUATION DECLARATIVE //////////////// -->
    <div v-if="accord.is_avis_situation_mandatory">
      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Avis de situation déclarative à l'impôt sur le revenu </h3>
          <em class="text-secondary">
            Il s'agit de la déclaration d'impôt de l'année {{accord.year}}.<br>
          </em>
        </div>
      </div>

      <div v-if="avisSituationDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="avisSituationDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Avis de situation<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="accord.state === '6MREFUSED'" class="text-secondary">Refusée par le manager</em>
              <em v-if="accord.state === '7MVALIDATED'" class="text-secondary"> Validée par le manager</em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click="conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, true, 'AVSITUA')">
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateDocumentInProcess"
                @click='conseillerValidateAccordDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseDocumentInProcess"
                @click='conseillerRefuseAccordDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>

        <pdfViewer
          v-if="pdfViewerType === 'AVSITUA'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />

      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-button
            block variant="outline-info" size="sm" pill
            @click="showCreateAccordDocumentModal('AVSITUA')">
              Ajouter un fichier
          </b-button>
        </div>
      </div>
      <hr>
    </div>




    <!-- //////////////////////// TELEACTUALISATION DE POLE EMPLOI //////////////// -->
    <div v-if="accord.is_teleactualisation_mandatory">
      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Trois dernières téléactualisations de France Travail </h3>
          <em class="text-secondary">
            Il s'agit des trois dernières téléactualisations de France Travail réalisées par le bénéficiaire.<br>
          </em>
        </div>
      </div>

      <div v-if="teleactualisationDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="teleactualisationDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Téléactualisation<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="accord.state === '6MREFUSED'" class="text-secondary">Refusée par le manager</em>
              <em v-if="accord.state === '7MVALIDATED'" class="text-secondary"> Validée par le manager</em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click="conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, true, 'TELEACTU')">
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateDocumentInProcess"
                @click='conseillerValidateAccordDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseDocumentInProcess"
                @click='conseillerRefuseAccordDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>

        <pdfViewer
          v-if="pdfViewerType === 'TELEACTU'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />

      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-button
            block variant="outline-info" size="sm" pill
            @click="showCreateAccordDocumentModal('TELEACTU')">
              Ajouter un fichier
          </b-button>
        </div>
      </div>
      <hr>
    </div>


    <!-- //////////////////////// ATTESTATIONS DE PAIEMENT  DE POLE EMPLOI //////////////// -->
    <div v-if="accord.is_attestation_paiement_mandatory">
      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Attestations de paiement de France Travail </h3>
          <em class="text-secondary">
            Il s'agit de l'attestation qui indique les denières paiements perçus par France Travail.<br>
          </em>
        </div>
      </div>

      <div v-if="attestationsPaiementDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="attestationsPaiementDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Attestation de paiement<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="accord.state === '6MREFUSED'" class="text-secondary">Refusée par le manager</em>
              <em v-if="accord.state === '7MVALIDATED'" class="text-secondary"> Validée par le manager</em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click="conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, true, 'PAIEPE')">
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateDocumentInProcess"
                @click='conseillerValidateAccordDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseDocumentInProcess"
                @click='conseillerRefuseAccordDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>

        <pdfViewer
          v-if="pdfViewerType === 'PAIEPE'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />

      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-button
            block variant="outline-info" size="sm" pill
            @click="showCreateAccordDocumentModal('PAIEPE')">
              Ajouter un fichier
          </b-button>
        </div>
      </div>
      <hr>
    </div>


    <!-- //////////////////////// NOTIFICATION D'OUVERTURE DES DROITS ARE //////////////// -->
    <div class="row mt-3">
      <div class="col-12">
        <h3 style=" margin-bottom: 0px;">Notification(s) d'ouverture de vos droits ARE de France Travail</h3>
        <em class="text-secondary">
        Il s'agit de la dernière notification d'ouverture des droits ARE de France Travail au titre des annexes 8 ou
        10 du bénéficiaire.
        <span class="text-danger" v-if="enCouple && accord.beneficiaire.is_conjoint_intermittent">
          <br><strong>Alerte</strong>, le bénéficiaire a déclaré un conjoint intermittent, il doit également
          charger la dernière notification d'ouverture de droits de son conjoint
        </span>
        </em><br>
      </div>
    </div>

    <div v-if="ouvertureAreDocs.length === 0">
      <div class="row">
        <div class="col-sm-12 mt-2 mb-3 text-center">
          <em>Aucun document encore chargé </em>
        </div>
      </div>
    </div>

    <div v-else>
      <b-table
        responsive class="align-middle text-center mt-4 cursor-table"
        headVariant="light"
        :fields="documentsRenderFields"
        :items="ouvertureAreDocs"
        >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template v-slot:cell(file_name)="data">
          Notification d'ouverture<br>
          <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
        </template>

        <template v-slot:cell(state)="data">
          <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
          <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
          <strong v-else class="text-secondary">A valider</strong>
        </template>

        <template v-slot:cell(actions)="data">
          <div v-if="accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <em v-if="accord.state === '6MREFUSED'" class="text-secondary">Refusée par le manager</em>
            <em v-if="accord.state === '7MVALIDATED'" class="text-secondary"> Validée par le manager</em>
          </div>
          <div v-else>
            <b-button
              v-b-popover.hover.top="'Visualiser en pdf'"
              variant="outline-danger" class="ml-2" size="sm"
              @click="conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, true, 'ARE')">
              <v-icon name="file-pdf"/>
            </b-button>
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'VALIDATED'"
              variant="outline-success" class="ml-2" size="sm"
              :disabled="validateDocumentInProcess"
              @click='conseillerValidateAccordDocument(data.item.id)'>
              <v-icon name="check-circle"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'REFUSED'"
              variant="outline-danger" class="ml-2" size="sm"
              :disabled="refuseDocumentInProcess"
              @click='conseillerRefuseAccordDocument(data.item.id)'>
              <v-icon name="times-circle"/>
            </b-button>
          </div>
        </template>
      </b-table>

      <pdfViewer
        v-if="pdfViewerType === 'ARE'"
        :pdfSrc="pdfSrc"
        @close="closePdfViewer"
      />

    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <b-button
          block variant="outline-info" size="sm" pill
          @click="showCreateAccordDocumentModal('ARE')">
            Ajouter un fichier
        </b-button>
      </div>
    </div>

    <hr>

    <!-- //////////////////////// ATTESTATION PAIEMENT CAF //////////////// -->
    <div v-if="isFamilleMonoparentale">
      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;">Attestation(s) de paiement de la CAF</h3>
          <em class="text-secondary">
          Il s'agit de la ou les attestation(s) de paiement de la CAF (<strong>obligatoire car 
            famille monoparentale</strong>)
          </em><br>
        </div>
      </div>

      <div v-if="attestCafDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="attestCafDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Attestation de paiement<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="accord.state === '6MREFUSED'" class="text-secondary">Refusée par le manager</em>
              <em v-if="accord.state === '7MVALIDATED'" class="text-secondary"> Validée par le manager</em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click="conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, true, 'ATTESTCAF')">
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateDocumentInProcess"
                @click='conseillerValidateAccordDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseDocumentInProcess"
                @click='conseillerRefuseAccordDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>

        <pdfViewer
          v-if="pdfViewerType === 'ATTESTCAF'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />

      </div>

      <div class="row">
        <div class="col-sm-12 text-center">
          <b-button
            block variant="outline-info" size="sm" pill
            @click="showCreateAccordDocumentModal('ATTESTCAF')">
              Ajouter un fichier
          </b-button>
        </div>
      </div>
    </div>

    <hr>


    <!-- //////////////////////// RIB //////////////// -->
    <div 
      id="rib-section"
      :class="pdfViewerType === 'RIB' ? 'fixed-top container' : ''" 
      style="background-color: white;"
    >
      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Relevé d'identité bancaire (RIB) </h3>
          <em class="text-secondary">
            Il s'agit du relevé d'identité bancaire ou postal
          </em><br>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-sm-6">
          <b-form-group id="iban-group" label="Votre Iban" label-for="iban-input">
            <b-form-input
              v-model="accord.iban" type="text"
              v-mask="'NNNN NNNN NNNN NNNN NNNN NNNN NNN'"
              maxlength="50"
              placeholder="Iban"
              id="iban-input"
              @input="$v.accord.iban.$touch()"
              :state="$v.accord.iban.$dirty ? !$v.accord.iban.$error : null">
            </b-form-input>
            <small class="text-danger" v-if="$v.accord.iban.$error">Veuillez saisir un iban valide</small>
          </b-form-group>
        </div>
        <div class="col-12 col-sm-6">
          <b-form-group id="bic-group" label="Votre bic" label-for="bic-input">
            <b-form-input
              v-model="accord.bic" type="text"
              maxlength="11"
              placeholder="Bic"
              id="bic-input"
              @input="$v.accord.bic.$touch()"
              :state="$v.accord.bic.$dirty ? !$v.accord.bic.$error : null">
            </b-form-input>
            <small class="text-danger" v-if="$v.accord.bic.$error">Veuillez saisir un BIC valide</small>
          </b-form-group>
        </div>
      </div>
      <b-form-group id="domiciliation_bancaire-group" label="Domiciliation bancaire" label-for="domiciliation_bancaire-input">
        <b-form-input
          v-model="accord.domiciliation_bancaire" type="text"
          maxlength="249"
          placeholder="Domiciliation bancaire"
          id="domiciliation_bancaire-input"
          @input="$v.accord.domiciliation_bancaire.$touch()"
          :state="$v.accord.domiciliation_bancaire.$dirty ? !$v.accord.domiciliation_bancaire.$error : null">
        </b-form-input>
        <small class="text-danger" v-if="$v.accord.domiciliation_bancaire.$error">Votre domiciliation doit comporter entre 2 et 249 caractères.</small>
      </b-form-group>

      <div class="row mt-2">
        <div class="col-sm-12 text-center">
          <b-button
            block variant="outline-success" size="sm" pill
            @click="modifyAccordBankInformation"
            :disabled="modifyAccordInProcess || $v.accord.iban.$invalid || $v.accord.bic.$invalid || $v.accord.domiciliation_bancaire.$invalid || accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
              Enregistrer ces informations
          </b-button>
          <small v-if="accord.state === '6MREFUSED'" class="text-danger">Demande d'accord refusée</small>
          <small v-if="accord.state === '7MVALIDATED'" class="text-success">Demande d'accord déjà acceptée</small>
        </div>
      </div>
    </div>

    <div v-if="ribDocs.length === 0">
      <div class="row">
        <div class="col-sm-12 mt-2 mb-3 text-center">
          <em>Aucun document encore chargé </em>
        </div>
      </div>
    </div>

    <div v-else>
      <b-table
        responsive class="align-middle text-center mt-4 cursor-table"
        headVariant="light"
        :fields="documentsRenderFields"
        :items="ribDocs"
        >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template v-slot:cell(file_name)="data">
          Rib<br>
          <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
        </template>

        <template v-slot:cell(state)="data">
          <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
          <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
          <strong v-else class="text-secondary">A valider</strong>
        </template>

        <template v-slot:cell(actions)="data">
          <div v-if="accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <em v-if="accord.state === '6MREFUSED'" class="text-secondary">Refusée par le manager</em>
            <em v-if="accord.state === '7MVALIDATED'" class="text-secondary"> Validée par le manager</em>
          </div>
          <div v-else>
            <b-button
              v-b-popover.hover.top="'Visualiser en pdf'"
              variant="outline-danger" class="ml-2" size="sm"
              @click="conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, true, 'RIB')">
              <v-icon name="file-pdf"/>
            </b-button>
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'VALIDATED'"
              variant="outline-success" class="ml-2" size="sm"
              :disabled="validateDocumentInProcess"
              @click='conseillerValidateAccordDocument(data.item.id)'>
              <v-icon name="check-circle"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'REFUSED'"
              variant="outline-danger" class="ml-2" size="sm"
              :disabled="refuseDocumentInProcess"
              @click='conseillerRefuseAccordDocument(data.item.id)'>
              <v-icon name="times-circle"/>
            </b-button>
          </div>
        </template>
      </b-table>

      <pdfViewer
        v-if="pdfViewerType === 'RIB'"
        :pdfSrc="pdfSrc"
        @close="closePdfViewer"
      />

    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <b-button
          block variant="outline-info" size="sm" pill
          @click="showCreateAccordDocumentModal('RIB')">
            Ajouter un fichier
        </b-button>
      </div>
    </div>

    <hr>


    <!-- //////////////////////// AUTRE DOCUMENTS //////////////// -->
    <div class="row mt-3">
      <div class="col-12">
        <h3 style=" margin-bottom: 0px;"> Autres justificatifs </h3>
        <em class="text-secondary">
          Toutes autres pièces justifiant la demande du bénéficiaire.
        </em><br>
      </div>
    </div>


    <div v-if="otherDocs.length === 0">
      <div class="row">
        <div class="col-sm-12 mt-2 mb-3 text-center">
          <em>Aucun document encore chargé </em>
        </div>
      </div>
    </div>

    <div v-else>
      <b-table
        responsive class="align-middle text-center mt-4 cursor-table"
        headVariant="light"
        :fields="documentsRenderFields"
        :items="otherDocs"
        >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template v-slot:cell(file_name)="data">
          Autre<br>
          <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
        </template>

        <template v-slot:cell(state)="data">
          <strong v-if="data.item.state == 'REFUSED'" class="text-danger">Refusé à recharger par le bénéficiaire</strong>
          <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
          <strong v-else class="text-secondary">A valider</strong>
        </template>

        <template v-slot:cell(actions)="data">
          <div v-if="accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <em v-if="accord.state === '6MREFUSED'" class="text-secondary">Refusée par le manager</em>
            <em v-if="accord.state === '7MVALIDATED'" class="text-secondary"> Validée par le manager</em>
          </div>
          <div v-else>
            <b-button
              v-b-popover.hover.top="'Visualiser en pdf'"
              variant="outline-danger" class="ml-2" size="sm"
              @click="conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, true, 'OTHER')">
              <v-icon name="file-pdf"/>
            </b-button>
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedAccordDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'VALIDATED'"
              variant="outline-success" class="ml-2" size="sm"
              :disabled="validateDocumentInProcess"
              @click='conseillerValidateAccordDocument(data.item.id)'>
              <v-icon name="check-circle"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'REFUSED'"
              variant="outline-danger" class="ml-2" size="sm"
              :disabled="refuseDocumentInProcess"
              @click='conseillerRefuseAccordDocument(data.item.id)'>
              <v-icon name="times-circle"/>
            </b-button>
          </div>
        </template>
      </b-table>
      <pdfViewer
        v-if="pdfViewerType === 'OTHER'"
        :pdfSrc="pdfSrc"
        @close="closePdfViewer"
      />

    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <b-button
          block variant="outline-info" size="sm" pill
          @click="showCreateAccordDocumentModal('OTHER')">
            Ajouter un fichier
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf'

import { APIConseillerConnected } from '@/api/APIConseillerConnected'
import pdfViewer from '@/components/pdf/pdfViewer'
import renderMixins from '@/mixins/renderMixins'
import { fileOperations } from '@/mixins/utilMixins'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, decimal, integer } from 'vuelidate/lib/validators'
import { file_size_validation, file_extension_validation } from '@/validators/validators'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { isValidIban } from '@/validators/validators'

const apiConseillerConnected = new APIConseillerConnected()

export default {
  name: 'conseillerAccordDocumentsProfile',
  props: {
    accord: Object
  },
  components: {
    pdfViewer
  },
  mixins: [
    renderMixins,
    validationMixin,
    fileOperations,
    Loading
  ],
  data: function () {
    return {

      // ------- Abattements --------
      abattementValue: null,
      abattementConjointValue: null,
      abattementCoupleValue: null,

      documentsRenderFields: [
        { key: 'index', label: "#", tdClass: 'ui-helper-center'},
        { key: "file_name", label: "Votre document", tdClass: 'ui-helper-center'},
        { key: "state", label: "Statut", tdClass: 'ui-helper-center'},
        { key: "actions", label: "", tdClass: 'ui-helper-center'},
      ],

      attestDocs: [],
      naissanceDocs: [],
      impotDocs: [],
      ouvertureAreDocs: [],
      attestCafDocs: [],
      ribDocs: [],
      otherDocs: [],

      avisSituationDocs: [],
      teleactualisationDocs: [],
      attestationsPaiementDocs: [],

      isAccordDocumentsLoading: false,

      refuseDocumentButtonStyle: "outline-secondary",
      refuseDocumentInProcess: false,

      validateDocumentButtonStyle: "outline-secondary",
      validateDocumentInProcess: false,

      // -------- IBAN -----------
      modifyAccordInProcess: false,

      // ------- DOCUMENT --------
      createAccordDocumentFile: [],
      createAccordDocumentType: '',
      createAccordDocumentInProcess: false,

      // PDF
      pdfSrc: null,
      pdfViewerType: null
    }
  },
  computed: {
    abattements () {
      return this.accord.abattements.filter((abattement) => abattement.type == 'DEMANDEUR')
    },
    abattementsCouple () {
      return this.accord.abattements.filter((abattement) => abattement.type == 'COUPLE')
    },
    abattementsConjoint () {
      return this.accord.abattements.filter((abattement) => abattement.type == 'CONJOINT')
    },
    isFamilleMonoparentale () {
      if (this.accord.beneficiaire.situation === 'CELIB' || this.accord.beneficiaire.situation === 'DIV' || this.accord.beneficiaire.situation === 'SEP') {
        return true
      }
      else {
        return false
      }
    },
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAccordDocumentsLoading || this.createAccordDocumentInProcess || this.modifyAccordInProcess) {
        return true
      }
      return false
    },
    enCouple () {
      if (this.accord.beneficiaire.situation === 'MAR' || this.accord.beneficiaire.situation === 'PACS' || this.accord.beneficiaire.situation === 'VIEMAR') {
        return true
      }
      else {
        return false
      }
    },
    rfrTotal () {
      if (this.enCouple) {
        const totalAbattementCouple = this.abattementsCouple.reduce((total, abattement) => {
          return total + Number(abattement.montant)
        }, 0)
        const totalAbattementConjoint = this.abattementsConjoint.reduce((total, abattement) => {
          return total + Number(abattement.montant)
        }, 0)
        const totalAbattements = totalAbattementCouple + totalAbattementConjoint

        // Alors on est sur un couple
        if (this.accord.rfr_value && Number(this.accord.rfr_conjoint_value) > 0) {
          return Number(this.accord.rfr_value) + Number(this.accord.rfr_conjoint_value) + Number(totalAbattements)
        }
        else {
          return Number(this.accord.rfr_value) + Number(totalAbattements)
        }
      }
      else {
        const totalAbattements = this.abattements.reduce((total, abattement) => {
          return total + Number(abattement.montant)
        }, 0)
        // Alors on est sur un célib
        return Number(this.accord.rfr_value) + Number(totalAbattements)
      }
    },
    rfrPlafond () {
      return 60000 + 3000 * Number(this.accord.rfr_nb_children_fiscalement_a_charge)
    }
  },


  validations: {
    // BANK INFORMATION
    accord: {
      iban: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
        validIban(value) {
          return isValidIban(value)
        }
      },
      bic: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },

      domiciliation_bancaire: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(250)
      },
      rfr_value: {
        required,
        decimal,
        isSuperior0(value) {
          return value && value > 0
        }
      },
      rfr_conjoint_value: {
        decimal
      },
      rfr_nb_children_fiscalement_a_charge: {
        required,
        integer
      }
    },

    createAccordDocumentFile: {
      required,
      file_size_validation,
      file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },
  },

  created: function() {
    this.conseillerGetAllAccordDocuments()
  },
  methods: {
    addAbattement (type) {
      let value = this.abattementValue
      if (type == 'COUPLE') {
        value = this.abattementCoupleValue
      }
      if (type == 'CONJOINT') {
        value = this.abattementConjointValue
      }
      if (!value || value <= 0) {
        return true
      }
      this.modifyAccordInProcess = true
      apiConseillerConnected.createAbattement(
        this.token,
        this.$route.params.accord_id,
        {
          'montant': value,
          'type': type,
        }
      )
        .then((result) => {
          this.accord.abattements.push(result.data)
          if (type == 'COUPLE') {
            this.abattementCoupleValue = null
          }
          else if (type == 'CONJOINT') {
            this.abattementConjointValue = null
          }
          else {
            this.abattementValue = null
          }
        })
        .finally(() => {
          this.modifyAccordInProcess = false
        })
    },

    closePdfViewer () {
      this.pdfSrc = null,
      this.pdfViewerType = null
    },

    deleteAbattement (id) {
      this.modifyAccordInProcess = true
      apiConseillerConnected.deleteAbattement(
        this.token,
        id
      )
        .then(() => {
          const filteredAbattements = this.accord.abattements.filter((abattement) => abattement.id != id)
          this.accord.abattements = filteredAbattements
        })
        .finally(() => {
          this.modifyAccordInProcess = false
        })
    },

    // -------------------- ACCORD DOCUMENTS  --------------
    conseillerGetAllAccordDocuments () {
      this.isAccordDocumentsLoading = true
      this.attestDocs = []
      this.naissanceDocs = []
      this.impotDocs = []
      this.ouvertureAreDocs = []
      this.attestCafDocs = []
      this.ribDocs = []
      this.otherDocs = []
      this.avisSituationDocs = []
      this.teleactualisationDocs = []
      this.attestationsPaiementDocs = []

      apiConseillerConnected.getAllAccordDocuments(this.token, this.$route.params.accord_id)
        .then((result) => {
          var documentsLength = result.data.length;
          for (var i = 0; i < documentsLength; i++) {
            if (result.data[i]['type'] === 'ATTEST') {
              this.attestDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'NAISSANCE') {
              this.naissanceDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'IMPOTS') {
              this.impotDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'ARE') {
              this.ouvertureAreDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'ATTESTCAF') {
              this.attestCafDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'RIB') {
              this.ribDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'OTHER') {
              this.otherDocs.push(result.data[i])
            }

            else if (result.data[i]['type'] === 'AVSITUA') {
              this.avisSituationDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'TELEACTU') {
              this.teleactualisationDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'PAIEPE') {
              this.attestationsPaiementDocs.push(result.data[i])
            }
          }
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('AccordDetails getAllAccordDocuments API Error : ' + String(error))
        })
        .finally(() => {
          this.isAccordDocumentsLoading = false
        })
    },

    conseillerGetProtectedAccordDocument (document_id, document_name, as_pdf, pdf_type) {
      if (pdf_type) {
        this.pdfViewerType = pdf_type
      }
      if (this.pdfSrc) {
        window.URL.revokeObjectURL(this.pdfSrc)
      }
      apiConseillerConnected.getAccordProtectedDocument(this.token, document_id)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        if (as_pdf) {
          if (document_name.split('.').pop() !== "pdf"){
            var img = new Image()
            img.src = url
            var doc = new jsPDF()
            doc.addImage(img, 'JPEG', 0, 0, 200, 0)
            let blobPdf = new Blob([doc.output('blob')], {type: 'application/pdf'})
            let blobUrl = window.URL.createObjectURL(blobPdf)
            this.pdfSrc = blobUrl
          }
          else {
            this.pdfSrc = url
          }
        }
        else {
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', document_name)
          document.body.appendChild(link)
          link.click()
        }
      }, () => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
    },



    conseillerValidateAccordDocument (doc_id) {
      this.validateDocumentButtonStyle = "secondary"
      this.validateDocumentInProcess = true
      apiConseillerConnected.validateDocument(this.token, doc_id)
      .then(() => {
        this.conseillerGetAllAccordDocuments()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerBeneficiaireProfile validateDocument API Error : ' + String(error))
      })
      .finally(() => {
        this.validateDocumentButtonStyle = "outline-success"
        this.validateDocumentInProcess = false
      })
    },

    conseillerRefuseAccordDocument (doc_id) {
      this.refuseDocumentButtonStyle = "secondary"
      this.refuseDocumentInProcess = true
      apiConseillerConnected.refuseDocument(this.token, doc_id)
      .then(() => {
        this.conseillerGetAllAccordDocuments()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerBeneficiaireProfile refuseDocument API Error : ' + String(error))
      })
      .finally(() => {
        this.refuseDocumentButtonStyle = "outline-danger"
        this.refuseDocumentInProcess = false
      })
    },

    modifyAccordBankInformation () {
      this.modifyAccordInProcess = true
      apiConseillerConnected.modifyAccord(
        this.token,
        this.accord.id,
        {
          "iban": this.accord.iban,
          "bic": this.accord.bic,
          "domiciliation_bancaire": this.accord.domiciliation_bancaire
        }
      )
        .then((result) => {
          this.accord = result.data
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.modifyAccordInProcess = false
        })
    },

    modifyAccordRfrInformation () {
      this.modifyAccordInProcess = true

      apiConseillerConnected.modifyAccord(
        this.token,
        this.$route.params.accord_id,
        {
          'rfr_value': Number(this.accord.rfr_value),
          'rfr_conjoint_value': Number(this.accord.rfr_conjoint_value),
          'rfr_nb_children_fiscalement_a_charge': this.accord.rfr_nb_children_fiscalement_a_charge
        }
      )
        .then((result) => {
          this.accord = result.data
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.modifyAccordInProcess = false
        })
    },


    showCreateAccordDocumentModal (createAccordDocumentType ) {
      this.createAccordDocumentType = createAccordDocumentType
      this.$refs.createAccordDocumentModal.show()
    },

    createAccordDocument () {
      this.createAccordDocumentInProcess = true
      apiConseillerConnected.createAccordDocument(
        this.token, this.$route.params.accord_id, this.createAccordDocumentType, this.createAccordDocumentFile)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$refs.accordDocumentUploadedModal.show()
          this.createAccordDocumentFile = []
          this.createAccordDocumentType = ''
          this.conseillerGetAllAccordDocuments()
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('conseillerAccordDocumentsProfile createAccordDocument API Error : ' + String(error))
        })
        .finally(() => {
          this.$refs.createAccordDocumentModal.hide()
          this.createAccordDocumentInProcess = false
        })
    },
  }
}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
